.skills-title {
  font-size: 2.5rem;
  text-transform: uppercase;
  margin-top: 1rem;
  color: white;
}

.skills-img {
  height: 7rem;
  color: white;
}

.text-center{
  color: black;
}
.load{
  color: black;
}