.navbar {
  font-family: "Open Sans", sans-serif !important;
  padding: 2rem !important;
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
  background: black;
}

.hamburger-contain {
  cursor: pointer;
}

.nav-container {
  overflow-y: visible;
}

.logo-txt {
  color: white;
  font-weight: 700;
  font-size: 1.5rem;
}

.logo-icon {
  height: 2.5rem;
  margin-right: 0.8rem;
}

.right-nav-btn {
  border-radius: 4rem;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    276deg,
    rgb(255, 255, 255) 4%,
    rgb(255, 255, 255) 62%,
    rgb(255, 255, 255) 100%
  );
  position: relative;

  cursor: pointer;
  font-size: 1rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  padding: 0.9rem 2.8rem;
  border-radius: 4rem;
  color: black;
  letter-spacing: 0.2rem;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: black;
}

.right-nav-btn::after {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: -0.7rem;
  left: 0;
  height: 110%;
  width: 100%;
  opacity: 0.7;
  border-radius: 4rem;
  background: inherit;
  filter: blur(8rem);

  /* Blurring the element for shadow effect */
  -webkit-filter: blur(0.2rem);
  -moz-filter: blur(0.2rem);
  -o-filter: blur(0.2rem);
  -ms-filter: blur(2.2rem);
  filter: blur(0.8rem);
}

.portfolio-link:hover {
  color: black !important;
}

.hamburger-menu {
  margin-left: 2.5rem;
  cursor: pointer;
}

i {
  font-size: 2rem;
}
.fa-bars{
  color: white;
}
