body {
  font-family: "Oswald", sans-serif !important;
}

html {
  scroll-behavior: smooth;
}

/* // colors
$gradient1: #F25C78;
$gradient2: #F2388F;
$gradient3: #F27D52;
$gradient4: #F26B5E; */
